import { SortDirection } from 'react-virtualized'
import { TableColumnType } from 'components/common/data-display/Table/types'
import { TranscriptionLibraryItemDataKeys } from 'redux/reducers/transcription-library/types'
import { intl } from 'utils/common/intl'

export const COLUMNS: TableColumnType[] = [
  {
    id: TranscriptionLibraryItemDataKeys.Name,
    label: intl.formatMessage({
      id: 'component.transcription-library.table.column.name'
    }),
    dataKey: TranscriptionLibraryItemDataKeys.Name
  },
  {
    id: TranscriptionLibraryItemDataKeys.Status,
    label: intl.formatMessage({
      id: 'component.transcription-library.table.column.status'
    }),
    dataKey: TranscriptionLibraryItemDataKeys.Status,
    width: 160
  },
  {
    id: TranscriptionLibraryItemDataKeys.Duration,
    label: intl.formatMessage({
      id: 'component.transcription-library.table.column.duration'
    }),
    dataKey: TranscriptionLibraryItemDataKeys.Duration,
    width: 120
  },
  {
    id: TranscriptionLibraryItemDataKeys.CreatedAt,
    label: intl.formatMessage({
      id: 'component.transcription-library.table.column.created'
    }),
    dataKey: TranscriptionLibraryItemDataKeys.CreatedAt,
    width: 170,
    sortDirections: [SortDirection.ASC, SortDirection.DESC]
  },
  {
    id: TranscriptionLibraryItemDataKeys.ExpireAt,
    label: intl.formatMessage({
      id: 'component.transcription-library.table.column.expire'
    }),
    dataKey: TranscriptionLibraryItemDataKeys.ExpireAt,
    width: 170,
    sortDirections: [SortDirection.ASC, SortDirection.DESC]
  },
  {
    id: TranscriptionLibraryItemDataKeys.Actions,
    dataKey: TranscriptionLibraryItemDataKeys.Actions,
    width: 100
  }
]