const messages = {
  'component.common.user-greeting.text': '{email}',
  'component.common.auth.logout': 'Log out',
  'component.common.header.title': 'AI Audio Pipeline',
  'component.common.submit.text': 'Submit',
  'component.common.label.or': 'OR',
  'component.common.copy-to-clipboard.success': 'Copied to clipboard',
  'component.common.help-link.text': `Help & FAQ's`,
  'component.common.no-data.description': 'No Data',
  'component.common.help-link.header': 'Help & Support',
  'component.common.report-issue.text': 'Report an issue',
}

export default messages
