import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import { Routes } from 'routes/constants'
import { Loaders } from 'redux/reducers/loaders/types'
import { RootState } from 'redux/types/store'
import { RowMouseEventHandlerParams, TableCellProps, TableHeaderRowProps, WindowScroller } from 'react-virtualized'
import { TranscriptionLibraryListItem } from 'components/audio-pipeline/Transcriptions/types'
import { TranscriptionLibraryItemDataKeys, TranscriptionStatus } from 'redux/reducers/transcription-library/types'
import {
  COLUMNS,
  TABLE_HEADER_PORTAL_NODE_ID
} from 'components/audio-pipeline/Transcriptions/constants'
import { Card } from 'components/common/data-display/Card'
import { Table } from 'components/common/data-display/Table'
import { TranscriptionStatusValue } from 'components/audio-pipeline/Transcriptions/Status'
import { TranscriptionTableCellValue } from 'components/audio-pipeline/Transcriptions/DefaultTableCellValue'
import { TranscriptionLibraryItemActions } from 'components/audio-pipeline/Transcriptions/Actions'
import { TableHeaderRow } from 'components/common/data-display/Table/TableHeaderRow'
import { fetchTranscriptions } from 'redux/actions/transcription-library'
import { getIsLoading } from 'redux/selectors/loaders'
import { getTranscriptionLibraryListItems } from 'redux/selectors/transcription-library'
import { replaceUrlParams } from 'api/utils'
import styles from './styles.module.css'

export const Transcriptions: React.FC = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const isLoading = useSelector((state: RootState) => getIsLoading(state, Loaders.Transcriptions))
  const items = useSelector(getTranscriptionLibraryListItems)

  const cellRenderer = useCallback(({
    cellData,
    dataKey,
    rowData
  }: TableCellProps) => {
    const { id, status, expiryAfterDays } = rowData as TranscriptionLibraryListItem
    const disabled = status === TranscriptionStatus.Transcribing

    if (dataKey === TranscriptionLibraryItemDataKeys.Status) {
      const label = cellData === TranscriptionStatus.Expiring ? formatMessage({
          id: `component.transcription-library.transcription.status.expiring`
        }, { days: expiryAfterDays })
        : null

      return (
        <TranscriptionStatusValue
          status={cellData}
          label={label}
        />
      )
    }

    if (dataKey === TranscriptionLibraryItemDataKeys.Actions) {
      return <TranscriptionLibraryItemActions rowId={id} disabled={disabled} />
    }

    return (
      <TranscriptionTableCellValue
        id={dataKey}
        value={cellData}
        disabled={disabled}
      />
    )
  }, [formatMessage])

  const handleRowClick = useCallback(({ rowData }: RowMouseEventHandlerParams) => {
    const { id, status } = rowData

    if (status === TranscriptionStatus.Transcribed) {
      const url = replaceUrlParams({
        url: Routes.Transcript,
        urlParams: { id }
      })

      navigate(url)
    }

  }, [navigate])

  const renderCustomHeader = useCallback(({
    style,
    height,
    width,
    scrollbarWidth,
    className,
    columns
  }: TableHeaderRowProps) => {
    const domNode = document.getElementById(TABLE_HEADER_PORTAL_NODE_ID)

    if (!domNode) {
      return null
    }

    return (
      createPortal(
        // @ts-ignore
        <TableHeaderRow
          style={style}
          className={classNames(className, styles.headerRow)}
          height={height}
          width={width}
          scrollbarWidth={scrollbarWidth}
          columns={columns}
        />, domNode
      )
    )
  }, [])

  useEffect(() => {
    dispatch(fetchTranscriptions())
  }, [dispatch])

  return (
    <WindowScroller>
      {({ height, isScrolling, onChildScroll, scrollTop }) => {
        return (
          <>
            <div
              id={TABLE_HEADER_PORTAL_NODE_ID}
              className={styles.stickyHeader}
            />
            <Card
              className={styles.card}
              isLoading={isLoading && !items.length}
              shouldRenderNoDataLabel={!items.length}
            >
              {items.length && <Table
                rows={items}
                height={height}
                renderCustomHeader={renderCustomHeader}
                isScrolling={isScrolling}
                scrollTop={scrollTop}
                onScroll={onChildScroll}
                columns={COLUMNS}
                onRowClick={handleRowClick}
                cellRenderer={cellRenderer}
              />}
            </Card>
          </>
        )
      }}

    </WindowScroller>
  )
}