import React, { PropsWithChildren, useCallback } from 'react'
import classNames from 'classnames'
import { CardHeader } from 'components/common/data-display/Card/Header'
import { NoData } from 'components/common/other/NoData'
import { Loader } from 'components/common/feedback/Loader'
import styles from './styles.module.css'

export interface Props extends PropsWithChildren{
  id?: string
  dataCy?: string
  extra?: React.ReactNode
  extraClassName?: string
  titleClassName?: string
  headerClassName?: string
  contentWrapperClassName?: string
  title?: string
  className?: string
  isLoading?: boolean
  shouldRenderNoDataLabel?: boolean
  renderTitle?: (title?: string) => React.ReactNode
}

export const Card: React.FC<Props> = ({
  id,
  dataCy,
  children,
  extra,
  extraClassName,
  headerClassName,
  titleClassName,
  contentWrapperClassName,
  title,
  className,
  isLoading,
  shouldRenderNoDataLabel,
  renderTitle
}) => {
  const shouldRenderHeader = title || extra

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <Loader className={styles.loader} />
    }

    if (shouldRenderNoDataLabel) {
      return <NoData shouldRenderDescription className={styles.noData} />
    }

    return children
  }, [isLoading, shouldRenderNoDataLabel, children])

  return (
    <div
      id={id}
      data-cy={dataCy || `card-${id}`}
      className={classNames(styles.wrapper, className)}
    >
        {shouldRenderHeader && (
          <CardHeader
            title={title}
            extra={extra}
            className={headerClassName}
            titleClassName={titleClassName}
            extraClassName={extraClassName}
            renderTitle={renderTitle}
          />
        )}
      <div
        id={`${id}-content-wrapper`}
        className={classNames(
          {
            [styles.loading]: isLoading,
            [styles.noContent]: shouldRenderNoDataLabel
          },
          contentWrapperClassName
        )}
      >
        {renderContent()}
      </div>
    </div>
  )
}
