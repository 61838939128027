import React from 'react'
import { useIntl } from 'react-intl'
import { HELP_LINK_ITEMS, HELP_LINK_ITEMS_TRIGGER } from 'components/layouts/common/Header/HelpLink/helpLinkItems'
import { Typography, Dropdown } from 'antd'
import { CustomIcon } from 'components/common/general/CustomIcon'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import styles from './styles.module.css'

const { Text } = Typography

export const HelpLink = () => {
  const { formatMessage } = useIntl()
  const helpLink = formatMessage({
    id: 'component.common.help-link.header'
  })

  return (
    <Dropdown 
      menu={{ items: HELP_LINK_ITEMS }}
      trigger={HELP_LINK_ITEMS_TRIGGER} 
      overlayClassName={styles.helpDropdown}
    >
      <div className={styles.wrapper}>
        <CustomIcon type={CustomIconType.Info} className={styles.icon} />
        <Text className={styles.text}>{helpLink}</Text>
      </div>
    </Dropdown>
  )
}