import React, { MouseEventHandler, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { CustomIconType } from 'components/common/general/CustomIcon/types'
import { Tooltip } from 'components/common/data-display/Tooltip'
import { IconButton } from 'components/common/general/IconButton'
import { deleteTranscription, editTranscription } from 'redux/actions/transcription-library'
import styles from './styles.module.css'

interface Props {
  rowId: string
  disabled?: boolean
}

export const TranscriptionLibraryItemActions: React.FC<Props> = ({
  rowId,
  disabled
}) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const handleEdit = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.stopPropagation()
    dispatch(editTranscription(rowId))
  }, [dispatch, rowId])

  const handleDelete = useCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.stopPropagation()
    dispatch(deleteTranscription(rowId))
  }, [dispatch, rowId])

  const editTooltip = formatMessage({
    id: 'component.transcription-library.edit.tooltip'
  })

  const deleteTooltip = formatMessage({
    id: 'component.transcription-library.delete.tooltip'
  })

  return (
    <div className={styles.wrapper}>
      <Tooltip
        text={editTooltip}
        className={styles.tooltipWrapper}
        contentClassName={styles.tooltipContent}
      >
        <IconButton
          iconType={CustomIconType.Edit}
          className={styles.button}
          disabled={disabled}
          onClick={handleEdit}
        />
      </Tooltip>
      <Tooltip
        text={deleteTooltip}
        className={styles.tooltipWrapper}
        contentClassName={styles.tooltipContent}
      >
        <IconButton
          iconType={CustomIconType.Delete}
          className={styles.button}
          disabled={disabled}
          onClick={handleDelete}
        />
      </Tooltip>
    </div>
  )
}